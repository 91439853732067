/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-use-before-define */
/* eslint-disable radix */
import Swiper from 'swiper';
import { Navigation, A11y } from 'swiper/modules';
import { setEqualHeights, mobileViewMax } from './util';

const YourJourney = (() => {
    function checkContentOfJourneyItem() {
        const journeyItems = document.querySelectorAll('.js-journey-item');

        journeyItems.forEach((item) => {
            if (!item.hasChildNodes()) {
                item.remove();
            }
        });
    }

    function handleSwiper() {
        let init;

        if (window.innerWidth <= mobileViewMax) {
            init = true;
        } else {
            init = false;
        }

        if (init) {
            new Swiper('.js-journey-slider', {
                // configure Swiper to use modules
                modules: [Navigation, A11y],
                spaceBetween: 25,
                slidesPerView: 1.15,
                navigation: {
                    nextEl: '.journey-section__next-btn',
                    prevEl: '.journey-section__prev-btn',
                },
                a11y: {
                    prevSlideMessage: `${window.resources['slider.prev.slide.label']}`,
                    nextSlideMessage: `${window.resources['slider.next.slide.label']}`,
                    firstSlideMessage: `${window.resources['slider.first.slide.label']}`,
                    lastSlideMessage: `${window.resources['slider.last.slide.label']}`,
                },
            });
        }
        setEqualHeights($('.js-journey-item-content'), 4);
    }
    return {
        init() {
            handleSwiper();
        },
        resize() {
            setEqualHeights($('.js-journey-paragraph'), 4);
            setEqualHeights($('.js-journey-item-content'), 4);
            checkContentOfJourneyItem();
        },
    };
})();

export default (() => {
    $(document).ready(YourJourney.init);
    $(window).on('resize', YourJourney.resize);
})();
